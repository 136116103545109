import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import FloatingInput from "../fields/FloatingInputText";
import SelectInput from "../fields/SelectInput";
import { regexEmail } from "src/helpers/constants";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";
import { hubspotApi } from "src/api";
import ButtonBlock from "./ButtonBlock";

interface NewDemoTeamModalProps {
  fetchDemoTokens: () => void;
}

export interface DemoTokenEmail {
  email: string;
  hubspotID: number;
  amountOfTime: number;
}

const NewDemoModeTeamModal = ({ fetchDemoTokens }: NewDemoTeamModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const initialFormData: {
    email: string;
    hubspotID: number | null;
    amountOfTime: string;
  } = {
    email: "",
    hubspotID: null,
    amountOfTime: "5",
  };
  interface FormErrors {
    email: string;
    hubspotID: string;
  }

  const initialFormErrors: FormErrors = {
    email: "",
    hubspotID: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const validate: any = async (data: any) => {
    const errors: {
      email?: string;
      hubspotID?: string;
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.hubspotID) {
      errors.hubspotID = "hubspotID is required";
    }
    if (data.hubspotID) {
      setOnSubmitting(true);
      const res = await hubspotApi.checkHubspotIDUsage(Number(data.hubspotID));
      if (res.isUsed) {
        errors.hubspotID = `hubspotID already in use by Team with ID # ${res.isUsedTeam}`;
        setOnSubmitting(false);
      } else if (!res.isValid) {
        errors.hubspotID = "Not a valid hubspotID";
        setOnSubmitting(false);
      }
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = await validate(formData);
    if (errors.email || errors.hubspotID) {
      return setFormErrors(errors);
    } else {
      setOnSubmitting(true);
      const { email, hubspotID, amountOfTime } = formData;
      try {
        let result = await AdminApi.addDemoTeam({
          email: email.toLowerCase(),
          hubspotID: Number(hubspotID),
          amountOfTime:
            amountOfTime === "Infinity"
              ? parseFloat(amountOfTime)
              : parseInt(amountOfTime),
        });
        if (result.status) {
          setAlert({
            display: true,
            message: `New Demo Mode Token created for ${email}`,
            type: "success",
          });
          setFormData(initialFormData);
        }
      } catch (e: any) {
        setAlert({
          display: true,
          message:
            e.message ||
            "An error occurred trying to create a new Demo Mode Token",
          type: "error",
        });
        setOnSubmitting(false);
        setShowModal(false);
      } finally {
        fetchDemoTokens();
        setShowModal(false);
      }
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mx-4 mb-6 lg:mx-32">
        <div className="mb-3">
          <FloatingInput
            name={"email"}
            label={"Email"}
            type={"text"}
            onChange={handleChange}
            value={formData.email}
            error={formErrors.email}
          />
        </div>
        <div className="mb-3">
          <FloatingInput
            name="hubspotID"
            label="Hubspot ID"
            type="number"
            value={formData.hubspotID?.toString()}
            onChange={handleChange}
            error={formErrors.hubspotID}
          />
        </div>
        <div className="mb-2">
          <div>
            <SelectInput
              label={"Duration"}
              value={formData.amountOfTime.toString()}
              name={"amountOfTime"}
              labelProps={{
                className: "font-medium text-left ml-3",
              }}
              inputProps={{
                className:
                  "border border-secondary focus:ring-none rounded-[9px] h-[55px] ring-0",
              }}
              options={[
                { label: "1 Day", value: "1" },
                { label: "2 Day", value: "2" },
                { label: "3 Days", value: "3" },
                { label: "4 Days", value: "4" },
                { label: "5 Days", value: "5" },
              ]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={onSubmitting}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Submit"}
          disabled={onSubmitting}
        />
      </div>
    </>
  );
};

export default NewDemoModeTeamModal;
