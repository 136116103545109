import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { KeapData, UpdateKeapData } from "src/components/cards/KeapCard";

class KeapApi extends ApiTokenSupport {
  async fetchKeapData(keapID: number): Promise<KeapData> {
    const resp = await axios.post<KeapData>(
      "/api/admin/keapData",
      { keapID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateKeapData(data: UpdateKeapData): Promise<UpdateKeapData> {
    const resp = await axios.put<UpdateKeapData>(
      "/api/admin/keapData",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addCoins(data: {
    teamID: number;
    coins: number;
    message: string;
  }): Promise<boolean> {
    const resp = await axios.post<boolean>("/api/keap/add-coins", data);
    return resp.data;
  }
}

export default new KeapApi();
